<template>
    <div class="blogger-routes" v-if="translates && translates[langUrl]">
        <div class="blogger-routes__title">
            {{ translates[langUrl].pageTitle[lang] }}
            <div
                v-if="!isDesktop"
                class="blogger-routes__content__main-container__fiter-button"
                @click="filtersShow"
                ></div>
        </div>
        <div class="blogger-routes__content">
            <div class="blogger-routes__content__menu"  v-if="isDesktop">
                <div class="blogger-routes__content__menu__link-block">
                    <div class="blogger-routes__content__menu__link-block__title">{{ translates[langUrl].linkBlock_title[lang] }}</div>
                    <BaseButton
                        class="button-fill"
                        style="width: 100%"
                        @click="linkCopy">
                        {{ translates[langUrl].button_linkBlock[lang] }}
                    </BaseButton>
                    <div class="blogger-routes__content__menu__link-block__switcher">
                        <div class="blogger-routes__content__menu__link-block__switcher__title">{{ translates[langUrl].linkblock_customQuiz[lang] }}</div>
                        <v-switch style="margin-top: 0;"
                            :input-value="customQuiz"
                            @change="changeCustomisation"
                            hide-details
                        />
                    </div>
                </div>
                <div class="blogger-routes__content__menu__menu-container">
                    <div class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectLevels[lang] }}</div>
                        <template v-for="(level, index) in selectLevels">
                            <BaseCheckbox
                                :key="`levels-${index}`"
                                v-model="filter.level"
                                :value="level.value"
                                :label="level.label"
                                @change="applyFilters"
                            />
                        </template>
                    </div>
                    <div v-if="selectDirections.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectDirections[lang] }}</div>
                        <template v-for="(direction, index) in selectDirections">
                            <BaseCheckbox
                                :key="`directions-${index}`"
                                v-model="filter.directions"
                                :value="direction.code"
                                :label="direction.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="countries.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.directions = !showAll.directions"
                            >{{ showAll.directions ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>
                    <div v-if="selectTypes.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectTypes[lang] }}</div>
                        <template v-for="(type, index) in selectTypes">
                            <BaseCheckbox
                                :key="`types-${index}`"
                                v-model="filter.tripType"
                                :value="type.code"
                                :label="type.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="types.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.types = !showAll.types"
                            >{{ showAll.types ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>      
                    <div v-if="selectMonths.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectMonths[lang] }}</div>
                        <template v-for="(month, index) in selectMonths">
                            <BaseCheckbox
                                :key="`months-${index}`"
                                v-model="filter.months"
                                :value="month.code"
                                :label="month.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="selectMonths.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.months = !showAll.months"
                            >{{ showAll.months ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>
                </div>
            </div>
            <div class="blogger-routes__content__main-container">
                <div v-if="!isDesktop && isFiltersShow" class="blogger-routes__content__main-container__menu">
                    <div class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectLevels[lang] }}</div>
                        <template v-for="(level, index) in selectLevels">
                            <BaseCheckbox
                                :key="`levels-${index}`"
                                v-model="filter.level"
                                :value="level.value"
                                :label="level.label"
                                @change="applyFilters"
                            />
                        </template>
                    </div>
                    <div v-if="selectDirections.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectDirections[lang] }}</div>
                        <template v-for="(direction, index) in selectDirections">
                            <BaseCheckbox
                                :key="`directions-${index}`"
                                v-model="filter.directions"
                                :value="direction.code"
                                :label="direction.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="countries.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.directions = !showAll.directions"
                            >{{ showAll.directions ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>
                    <div v-if="selectTypes.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectTypes[lang] }}</div>
                        <template v-for="(type, index) in selectTypes">
                            <BaseCheckbox
                                :key="`types-${index}`"
                                v-model="filter.tripType"
                                :value="type.code"
                                :label="type.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="types.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.types = !showAll.types"
                            >{{ showAll.types ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>      
                    <div v-if="selectMonths.length" class="blogger-routes__content__menu__block">
                        <div class="blogger-routes__content__menu__block-title">{{ translates[langUrl].filter_selectMonths[lang] }}</div>
                        <template v-for="(month, index) in selectMonths">
                            <BaseCheckbox
                                :key="`months-${index}`"
                                v-model="filter.months"
                                :value="month.code"
                                :label="month.title"
                                @change="applyFilters"
                            />
                        </template>
                        <div 
                            v-if="selectMonths.length > 3" class="blogger-routes__content__menu__show" 
                            @click="showAll.months = !showAll.months"
                            >{{ showAll.months ? `${ translates[langUrl].button_show_show[lang] }` : `${ translates[langUrl].button_show_hide[lang] }` }}</div>
                    </div>
                </div>
                <template v-for="(trip, index) in trips">
                    <RoutePreviewCard
                        v-if="isDesktop"
                        :key="index"
                        :trip="trip"
                        :blogger-trips="true"
                        :buttons="['bloggerRequest']"
                        class="blogger-routes__content__trip"
                        :qualified="summary.qualified"
                    />
                    <RoutePreviewCardMobile
                        v-else
                        :key="index"
                        :trip="trip"
                        :blogger-trips="true"
                        :qualified="summary.qualified"
                    />
                </template>

                <BaseButton
                    v-if="showMoreButton || !trips"
                    class="blogger-routes__show-more-button"
                    @click="showMore">
                    {{ translates[langUrl].button_showMore[lang] }}
                </BaseButton>
            </div>
        </div>

        <BaseAlertDialog
            v-model="linkDialog"
            :buttonText="translates[langUrl].alertDialog_buttonText[lang]"
            @click="linkDialog = false"
        >{{ translates[langUrl].alertDialog_text[lang] }}</BaseAlertDialog>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE, TRIP_LEVEL_TITLE } from '@/vars';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue';
    import BaseAlertDialog from '../../components/common/BaseAlertDialog.vue';
    import RoutePreviewCard from '../../components/common/trip/RoutePreviewCard.vue';
    import RoutePreviewCardMobile from '../../components/common/trip/RoutePreviewCardMobile.vue';

    export default {
        name: 'blogger-routes',
        metaInfo() {
            return {
                title: this.translates && this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            BaseCheckbox,
            BaseAlertDialog,
            RoutePreviewCard,
            RoutePreviewCardMobile
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('quiz', ['summary']),
            ...mapState('dictionaries', ['types', 'countries', 'cities']),
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            showMoreButton() {
                return this.page * this.itemsPerPage < this.tripsCount;
            },
            selectLevels() {
                const levels = [];
                for(const key in TRIP_LEVEL_TITLE) {
                    levels.push({ label: TRIP_LEVEL_TITLE[key], value: key });
                }
                return levels;
            },
            selectDirections() {
                return this.countries ? (this.showAll.directions ? this.countries : this.countries.slice(0, this.showAll.itemsCount)) : [];
            },
            selectTypes() {
                return this.types ? (this.showAll.types ? this.types : this.types.slice(0, this.showAll.itemsCount)) : [];
            },
            selectMonths() {
                return [];
            },
            quizLink() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/${this.customQuizValue}/${this.user.social || null}`;
            },
        },
        data: () => ({
            TRIP_STATUS,
            TRIP_TYPE,
            TRIP_LEVEL_TITLE,
            isMobile,
            isDesktop: false,
            linkDialog: false,
            customQuiz: false,
            customQuizValue: 'common',
            showAll: {
                itemsCount: 3,
                directions: false,
                types: false,
                months: false
            },
            page: 1,
            itemsPerPage: 6,
            filter: {
                level: [],
                directions: [],
                tripType: []
            },
            isFiltersShow: false,
            langUrl: '/blogger/trips/all'
        }),
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            store.commit('trips/CLEAR_ENTITIES');
            await this.getTrips();
            await store.dispatch('quiz/summary', { qualified: true });
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async linkCopy() {
                const quizLink = this.quizLink
                navigator.clipboard.writeText(quizLink).then(() => this.linkDialog = true);
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.HOST,
                    status: TRIP_STATUS.ACTIVE,
                }, sorting: this.sorting, page: this.page, itemsPerPage: this.itemsPerPage, byPages: false });
            },
            filtersShow() {
                this.isFiltersShow = !this.isFiltersShow
            },
            async applyFilters() {
                this.page = 1;
                await this.getTrips();
            },
            async showMore() {
                this.page += 1;
                await this.getTrips();
            },
            changeCustomisation(value) {
                this.customQuiz = value
                if(this.customQuiz === true) {
                    this.customQuizValue = 'blogger'
                } else {
                    this.customQuizValue = 'common'
                }
            }
        },
    };
</script>

<style lang="scss">
.blogger-routes {
    display: flex;
    flex-direction: column;

    &__show-more-button {
        width: 100%;
    }

    &__title {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        padding: 40px 0 0;
        display: flex;
        justify-content: space-between;
        @media all and (max-width: 768px) {
            background: #FFFFFF;
            padding: 20px 20px 0;
            font-size: 24px;
            line-height: 140%;
        }
    }

    &__header {
        position: relative;
        width: 100%;
        height: 240px;
        background: url('../../assets/bloggers/audience-bg.png') center center no-repeat;
        background-size: cover;
        border-radius: 0px 0px 12px 12px;
        overflow: hidden;

        @media all and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../assets/bloggers/audience-bg-mobile.png') center center no-repeat;
            background-size: cover;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 40px;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #FFFFFF;
            
            @media all and (max-width: 768px) {
                position: relative;
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.02em;
            }
        }
    }

    &__quiz-link {
        height: 125px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 12px 20px 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        background: linear-gradient(270deg, rgba(225, 23, 85, 0.48) 0%, rgba(225, 23, 85, 0) 100%);
            
        @media all and (max-width: 768px) {
            height: 90px;
            padding: 20px;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            color: #273155;
            background: #FFFFFF;

            .base-button {
                margin-left: 20px;
            }
        }
    }

    &__content {
        width: 100%;
        padding: 50px 0;
        margin-right: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 auto;

        @media all and (max-width: 1320px) {
            background: #FFFFFF;
            padding: 50px 20px;
        }

        @media all and (max-width: 768px) {
            background: #FFFFFF;
            padding: 30px 0 10px;
        }

        &__menu {
            width: 300px;
            padding: 20px;
            margin-right: 32px;
            background: #FFFFFF;
            border-radius: 12px;

            &__link-block {
                background: #FFFFFF;
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.07);
                border-radius: 12px;
                padding: 20px;
                margin-bottom: 20px;
                &__title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    color: #273155;
                    margin-bottom: 16px;
                }
                &__switcher {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;
                    &__title {
                        margin-top: 3px;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 17px;
                        letter-spacing: -0.02em;
                        // color: #9FA5AD;
                    }
                }
            }

            &__menu-container {
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.07);
                border-radius: 12px;
                padding: 20px;
            }

            &__block {
                padding: 17px 0;
                border-bottom: 1px #dddddd solid;
                &:last-of-type {
                    border-bottom: none;
                }
                // margin-bottom: 17px;
            }
            &__block-title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 12px;
            }
            &__show {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #3107D8;
                cursor: pointer;
            }
        }
        
        &__main-container {
            width: 100%;
            // max-width: 702px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            @media all and (max-width: 768px) {
                max-width: auto;
            }

            &__fiter-button {
                padding: 17px;
                background-color: crimson;
                background-image: url(../../assets/Filter-icon.svg);
                background-position: center;
                border-radius: 12px;
            }

            &__menu {
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.07);
                border-radius: 12px;
                padding: 20px;
                width: calc(100% - 40px);
                margin: 0 auto 20px;
            }
        }

        &__trip {
            width: 100%;
            margin: 0 0 20px;

            @media all and (max-width: 910px) {
                width: 100%;
            }
        }
    }

}
</style>